import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  getLoadingStatus,
  getSpecificCampaignThunk,
} from "redux/slices/campaignSlice";
import { CampaignParticipatns } from "types/Campaign";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Icon, IconButton } from "@mui/material";
import MoreActionsMenu from "components/MoreActionsMenu";
import {
  markAsReadyCampaignDrivers,
  removeCampaignDrivers,
} from "services/api/campaignApi";
import MDButton from "components/MDButton";
import AddParticipantModal from "./AddModal";

interface TableProps {
  header: ITableHeader[];
  rows: CampaignParticipatns[];
  campaignId: number;
}
const moreActions = [
  {
    name: "Mark as ready",
    icon: <Icon fontSize="small">check_circle</Icon>,
  },
  {
    name: "Remove",
    icon: <Icon fontSize="small">unpublished</Icon>,
  },
];

const CampaignParticipantsTable: React.FC<TableProps> = (props) => {
  const { header, rows, campaignId } = props;
  const dispatch = useDispatch();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();
  const [selectedRow, setSelectedRow] = useState<
    CampaignParticipatns | undefined
  >();
  const accessToken = localStorage.getItem("carching-token");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleActionButtons = async (action?: string): Promise<void> => {
    if (accessToken && !!selectedRow) {
      if (action === "Mark as ready") {
        await markAsReadyCampaignDrivers(selectedRow.id, accessToken);
      } else if (action === "Remove") {
        await removeCampaignDrivers(
          selectedRow.id,
          {
            status_id: 41,
          },
          accessToken,
        );
      }
      if (action) {
        alert(
          `${action} successfully. You need to refresh the page in order to check the update`,
        );
      }
    }
    openDialogConfirmation(undefined);
    setSelectedRow(undefined);
  };

  const handleReload = () => {
    const accessToken = localStorage.getItem("carching-token") as string;
    dispatch(getSpecificCampaignThunk({ campaignId, accessToken }));
  };

  return (
    <>
      <AddParticipantModal
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        campaignId={campaignId}
      />
      <Box sx={{ width: "100%" }}>
        {!!isDialogOpenWithAction && (
          <ConfirmationDialog
            title={`Are you sure you want to ${isDialogOpenWithAction} this driver?`}
            open={!!isDialogOpenWithAction}
            onClose={(action?: string) => handleActionButtons(action)}
            action={isDialogOpenWithAction}
            submitButtonTitle={isDialogOpenWithAction}
          />
        )}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            numSelected={0}
            tableName={"Campaign"}
            onReload={handleReload}
          />
          <MDBox display={"flex"} justifyContent={"end"}>
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                setModalOpen(true);
              }}
              style={{ marginRight: 8 }}
            >
              Add Campaign Participant
            </MDButton>
          </MDBox>
          {isLoading ? (
            <MDBox display={"flex"} justifyContent={"center"} mt={3}>
              <CircularProgress color={"primary"} />
            </MDBox>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHeader
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={header}
                  disableCheckbox={true}
                />
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="right">{row.id}</TableCell>
                        <TableCell align="right">
                          {row.driver && row.driver.user
                            ? `${row.driver.user.fname} ${row.driver.user.lname}`
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {row.driver ? row.driver.car_plate : ""}
                        </TableCell>

                        <TableCell align="right">{`${new Date(
                          row.registered_at,
                        ).toLocaleDateString()}`}</TableCell>
                        <TableCell align="right">
                          {row.status ? row.status.name : ""}
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            href={`https://wa.me/6${
                              row.driver ? row.driver.user.phone : ""
                            }`}
                            target="_blank"
                          >
                            <IconButton>
                              <WhatsAppIcon sx={{ color: "#25d366" }} />
                            </IconButton>
                          </Link>
                          <MoreActionsMenu
                            menuItems={moreActions}
                            onActionSelected={(action: string) => {
                              setSelectedRow(row);
                              openDialogConfirmation(action);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.length - 1}
            rowsPerPage={15}
            page={rows.length <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </>
  );
};
export default CampaignParticipantsTable;
