import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import AvatarUpload from "components/AvatarUpload";
import { validateForm } from "./validateForm";
import ModalHeaderTitle from "./Header";
import { NewUser } from "types/Authentication";
import MDButton from "../../../components/MDButton";
import { sendUserEmailVerification } from "../../../services/api/usersApi";

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  password_confirmation: "",
  phone: "",
  profile_picture_url: "",
};

const EMAIL_VERIFICATION_COUNTDOWN = 30;

type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedUser?: NewUser;
  addEditUser: (updatedUser: NewUser) => void;
};

const AddEditUserModal: React.FC<AddEditUserModalProp> = (props) => {
  const { open, handleClose, isEdit, selectedUser, addEditUser } = props;
  const formikRef = useRef<any>(null);
  const [resendCountdown, setResendCountdown] = useState(0);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };

  const onResendEmailVerification = async () => {
    const accessToken = localStorage.getItem("carching-token") || "";
    if (resendCountdown > 0 || !accessToken || !selectedUser) return;

    try {
      const response = await sendUserEmailVerification(
        selectedUser.email,
        accessToken,
      );

      if (response.success) {
        setResendCountdown(EMAIL_VERIFICATION_COUNTDOWN);
        const interval = setInterval(() => {
          setResendCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(interval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        return;
      }
    } catch (e:any) {
        console.error(e);
    }
  };

  const onSubmit = (values) => {
    if (
      isEdit &&
      (values.password === "" || values.password_confirmation === "")
    ) {
      delete values.password_confirmation;
      delete values.password;
    } else {
      const user: NewUser = {
        role_id: 3,
        country_code: "+60",
        ...values,
      };
      addEditUser(user);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} User`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedUser ? selectedUser : initialValues
            }
            validate={(values) => {
              if (!isEdit) validateForm(values);
            }}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <AvatarUpload
                  selectedImage={""}
                  handleImageUpload={(image) =>
                    setFieldValue("profile_picture_url", image)
                  }
                />
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="First Name"
                      name="fname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fname}
                      fullWidth
                      error={!!errors.fname}
                      helperText={errors.fname}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Last Name"
                      name="lname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lname}
                      fullWidth
                      error={!!errors.lname}
                      helperText={errors.lname}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="email"
                      label="Email"
                      name="email"
                      disabled={isEdit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={errors.email}
                      fullWidth
                      error={!!errors.email}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="number"
                      label="Phone Number (+60)"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      helperText={errors.phone}
                      fullWidth
                      error={!!errors.phone}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="password"
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={!!errors.password}
                      helperText={errors.password}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="password"
                      label="Confirm Password"
                      name="password_confirmation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password_confirmation}
                      error={!!errors.password_confirmation}
                      helperText={errors.password_confirmation}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          {isEdit && (
            <>
              <MDButton
                disabled={resendCountdown > 0}
                variant="secondary"
                onClick={onResendEmailVerification}
              >
                {resendCountdown > 0
                  ? `Resend in ${resendCountdown}s`
                  : "Resend Email"}
              </MDButton>
            </>
          )}
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit user" : "Add user"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditUserModal;
