import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import { AllUsers } from "types/AllUsers";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import InputDebounce from "../../components/MDInput/inputDebounce";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "redux/slices/allUsersSlice";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { NewUser } from "types/Authentication";
import { format } from "date-fns";

type TableType = {
  header: ITableHeader[];
  rows: AllUsers;
  onPageChanged: (newPage: number) => void;
  downloadIntoCsv: () => void;
  handleUserSearch: (searchInput: string) => void;
  onReload: (currentPage: number) => void;
  addNewOrEditUser: (isEdit: boolean, selectedUser?: NewUser) => void;
};
const SortedTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    onPageChanged,
    downloadIntoCsv,
    handleUserSearch,
    onReload,
    addNewOrEditUser,
  } = props;
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          tableName={"Users"}
          onReload={() => onReload(page)}
        />
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
          padding={"10px"}
        >
          <InputDebounce
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleUserSearch}
            width={"60%"}
          />

          <MDBox display={"flex"} justifyContent={"end"}>
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => addNewOrEditUser(false)}
              style={{ marginRight: 8 }}
            >
              Add User
            </MDButton>
            <MDButton
              variant="gradient"
              color="primary"
              onClick={downloadIntoCsv}
            >
              Download as CSV
            </MDButton>
          </MDBox>
        </MDBox>
        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
              />
              <TableBody>
                {data.map((row, index) => {
                  const isItemSelected = isSelected(row.username);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.username)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.id}
                      </TableCell>

                      <TableCell align="right">{`${row.fname}  ${row.lname}`}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.source}</TableCell>
                      <TableCell align="right">
                        {row.email_verified_at ? format(new Date(row.email_verified_at), "h:mma  dd/MM/yyyy") : "(empty)"}
                      </TableCell>
                      <TableCell
                        align="right"
                        onClick={(e) => {
                          //Prevent whole  row is being selected
                          e.stopPropagation();
                          e.preventDefault();
                          addNewOrEditUser(true, {
                            password_confirmation: "",
                            password: "",
                            ...row,
                          } as NewUser);
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            addNewOrEditUser(true, {
                              password_confirmation: "",
                              password: "",
                              ...row,
                            } as NewUser);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default SortedTable;
