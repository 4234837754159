export function handleError(e: any) {
    if (e.response) {
        // The request was made and the server responded with a status code
        console.error("Server responded with an error:", e.response.data);
        console.error("Status code:", e.response.status);
        console.error("Headers:", e.response.headers);
    } else if (e.request) {
        // The request was made but no response was received
        console.error("No response received:", e.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error in setting up the request:", e.message);
    }
    console.error("Config:", e.config);
}